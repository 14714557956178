export const keywords = [
  `dome`,
  `geodesic`,
  `alternative housing`,
  `dome home`,
  `dome kit`,
  `dome model`,
  `dodecahedron`,
  `diy dome`,
  `dome plans`,
  `wooden dome`,
]